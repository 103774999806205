<template>
  <div>
    <a-descriptions
      :column="1"
      class="iotplt-descriptions"
    >
      <a-descriptions-item label="事件性质">
        {{ data.transfer_type }}
      </a-descriptions-item>

      <a-descriptions-item label="收付款日期">
        {{ data.paid_at }}
      </a-descriptions-item>

      <a-descriptions-item label="支付方式">
        {{ data.pay_way }}
      </a-descriptions-item>

      <a-descriptions-item label="金额(元)">
        {{ data.amount }}
      </a-descriptions-item>

      <a-descriptions-item label="记录人">
        {{ data.operator_name }}
      </a-descriptions-item>

      <a-descriptions-item label="记录时间">
        {{ data.created_at }}
      </a-descriptions-item>

      <a-descriptions-item label="备注">
        {{ data.remark }}
      </a-descriptions-item>
    </a-descriptions>
  </div>
</template>

<script>
export default {
  name: 'BalanceStatisticTopAgentViewAgencyTransferInfo',
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>
